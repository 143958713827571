import * as React from "react"
import {useState} from "react"
import Layout from "../components/Layout"
import {GatsbyImage, getImage, IGatsbyImageData, StaticImage} from "gatsby-plugin-image";
import '../styles/styles.scss'
import Carousel from "react-multi-carousel";
import {graphql, Link} from "gatsby";
import 'react-multi-carousel/lib/styles.css';
import * as styles from "../styles/our-tequila.module.scss"
import Pina from '../images/inline/pina.inline.svg'
import Agave from '../images/inline/agave.inline.svg'
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

interface Props {
    data: {
        allMarkdownRemark: any
    }
}

const OurTequilaPage = ({data}: Props) => {
    const {allMarkdownRemark} = data;
    const {edges: tequilas} = allMarkdownRemark
    const [actualLanguage, setActualLanguage] = useState('es')
    const {language} = useI18next();
    const responsive = {
        all: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 0},
            items: 1,
        }
    };
    const responsiveTequilas = {
        all: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 0},
            items: 3,
        },
        mobile: {
            // the naming can be any, depends on you.
            breakpoint: {max: 1200, min: 0},
            items: 1,
        }
    };

    const lines = getLines(language)

    function getLines(lang: string): { images: Array<IGatsbyImageData>; lines: Array<string>; detailsImages: Array<IGatsbyImageData> } {

        let lines: Array<string> = []
        let linesImages: Array<IGatsbyImageData> = []
        let detailImages: Array<IGatsbyImageData> = []
        tequilas.forEach((tequilaContent: { node: any; }) => {
            const {node} = tequilaContent
            const {frontmatter: tequila} = node
            if (lang === "es" && !lines.includes(tequila.line)) {
                lines.push(tequila.line)
                linesImages.push(getImage(tequila.cover_img.childImageSharp) as IGatsbyImageData)
                detailImages.push(getImage(tequila.detail_img.childImageSharp) as IGatsbyImageData)
            } else if (lang === "en" && !lines.includes(tequila.line_en)) {
                lines.push(tequila.line_en)
                linesImages.push(getImage(tequila.cover_img.childImageSharp) as IGatsbyImageData)
                detailImages.push(getImage(tequila.detail_img.childImageSharp) as IGatsbyImageData)
            }
        })
        return {
            lines: lines,
            images: linesImages,
            detailsImages: detailImages
        }
    }

    const [lineActive, setLineActive] = useState(lines?.lines[0])
    return (
        <Layout footer={false}>
            <div className={styles.tequilaCarousel}>
                <div  className={styles.itemCarousel}>
                    {/* <GatsbyImage alt={"Santaleza"} image={lines.images[0]}/> */}
                    <StaticImage className={styles.image} src={'../tequilas-pages/STLZ_Family3-archive.jpg'} alt={'Santaleza'}/>
                    <h2>
                        <Pina/> <br/>
                        <b>Santaleza</b></h2>
                    <GatsbyImage className={styles.detail} alt={'Santaleza'} image={lines.detailsImages[0]}/>
                </div>
                {/*<Carousel
                    focusOnSelect
                    infinite
                    slidesToSlide={0}
                    additionalTransfrom={0}
                    arrows={false}
                    draggable={false}
                    shouldResetAutoplay={false}
                    autoPlay={false}
                    centerMode
                    responsive={responsive}>
                    {lines.lines.map((line, index) => {

                        return (
                            <button onClick={() => setLineActive(line)} className={styles.itemCarousel}>
                                <GatsbyImage alt={line} image={lines.images[index]}/>
                                <h2>
                                    <Pina/> <br/>
                                    <b>{line}</b></h2>
                                <GatsbyImage className={styles.detail} alt={line} image={lines.detailsImages[index]}/>
                            </button>
                        )
                    })}

                </Carousel>*/}
            </div>
            <div className={styles.tequilaInfo}>
                <p><Trans>grados</Trans><br/>
                    <Trans>type of agave</Trans><br/>
                    750 ML
                </p>
            </div>
            <div className={styles.tequilaList}>
                <div className={styles.title}>
                    <Agave/>
                    <h2>{lineActive}</h2>
                </div>
                <div className={styles.content}>
                    <Carousel
                        arrows={true}
                        responsive={responsiveTequilas}
                        shouldResetAutoplay={false}
                        autoPlay={false}>
                        {tequilas.map((content: { node: { frontmatter: any; }; }) => {
                            const tequila = content.node.frontmatter,
                                image = getImage(tequila.bottle_img) as IGatsbyImageData
                            return tequila ?
                                <Link key={tequila.slug} to={tequila.available <= 0 ? '#': tequila.slug} className={styles.itemCarousel}>
                                    <GatsbyImage className={styles.bottle} alt={tequila.title} image={image}/>
                                    <p>{tequila.title}
                                        {tequila.available <= 0 ? <><br/><Trans>Coming soon</Trans></> : ''}
                                    </p>
                                </Link> : <></>
                        })}
                    </Carousel>
                </div>
            </div>

        </Layout>
    )
}

export const pageQuery = graphql`
     query OurTequilaQuery ($language: String!) {
      allMarkdownRemark(sort: {fields: frontmatter___order, order: ASC}) {
        edges {
          node {
            frontmatter {
              slug
              title
              line
              available
              line_en
              detail_img {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              bottle_img { 
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              cover_img { 
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
      locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
  }
`


export default OurTequilaPage
